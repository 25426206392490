body {
  font-family: 'Karla', sans-serif;
}
.App{
  padding: 0px 5vw ;
}

header {
  font-family: 'Karla', sans-serif;
  display: flex;
  align-items: center;
  height: 100px;
  background: linear-gradient(90deg, #2f4929 1.18%, #4b881a 100%);
  color: white;
  padding: 20px 75px;
  border-radius: 50px 50px 100px 100px;
}
main {
  padding: 36px 20vw;
}

.header--img {
  height: 100%;
  border-radius: 50px 50px 100px 100px;
  margin-right: 16px;
}
.header--title {
  font-size: 30px;
  margin-right: auto;
}
.header--project {
  font-size: 22px;
  font-weight: 500;
}

form {
  display: grid;
  grid-template: 40px 40px / 1fr 1fr;
  gap: 15px;
  position: relative;
}
.form--button {
  font-family: 'Karla', sans-serif;
  background: linear-gradient(90deg, #3c463f 1.18%, #6dc783 100%);
  grid-column: 1/-1;
  color:white;
  border-radius: 5px;
  cursor: pointer;
  border:none;
  font-size:large;
  width: 46vw;
}
.form--center{
  text-align: center;
}

.form--input {
  border-radius: 5px;
  font-family: 'Karla', sans-serif;
  border: 1px solid black;
  text-indent: 5px;
  width: 20vw;
}
.monke--image {
  border-radius: 5px;
  padding-top:30px;
  width:45vw;
}

.monke{
  display: flex;
  position: relative;
}

.monke--text {
    position: absolute;
    width:80%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 40px 0px;
    padding: 0 5px;
    font-family: impact, sans-serif;
    font-size: 3.5vw;
    text-transform: uppercase;
    color: white;
    letter-spacing: 1px;
    text-shadow:
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000,
        0 2px 0 #000,
        2px 0 0 #000,
        0 -2px 0 #000,
        -2px 0 0 #000,
        2px 2px 5px #000;
}
.top{
  top: 0;
   
}
.bottom{
  bottom: 0;
}
@media (max-width: 850px) {

header{
  height: 50px;
  padding: 10px 45px;

}
.header--title{
  font-size: 20px;
}
.header--project{
  font-size: 15px;
}
}